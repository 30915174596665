/* You can add global styles to this file, and also import other style files */

/* p, h2, h1, h2, h3, h4,h5, h6,li{
      zoom : 120%;
} */

 p, li{
      color:#757575

 }

 